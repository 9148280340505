body {
  &.overflow {
    @media(min-width:415px) {
      overflow: auto;
    }
  }
}
.header {
  display: flex;
  position: fixed;
  width: 100%;
  height: $headerHeight;
  top: 0;
  right: 0;
  left: 0;
  @include headerFooterPadding();
  background: $w;
  flex-direction: row;
  align-items: center;
  z-index: 200;

  @include respond-down(large) {
    height: 85px;
    justify-content: space-between;
  }
  @include respond-down(middle) {
    justify-content: space-between;
    padding-top: 0;
  }
  @include respond(small) {
    height: auto;
    padding-right: 0px;
    align-items: center;
  }

  &__logo {
    display: block;
    flex: 0 0 14.6%;

    .logo-image {
      width: 254px;
      height: 55.5px;

      img {
        object-fit: contain;
        object-position: left center;
        height: 100%;
      }

      @include respond-down(large) {
        height: 50px;
        width: 135px;
      }


      @include respond-down(medium) {
        width: 156px;
        height: 42px;
      }

      @include respond-down(small) {
        width: 150px;
        height: 56px;
      }
    }

    @include respond-down(large) {
      flex: 0 0 12%;
      padding: 0 10px 0 0;
    }
  }

  &__menu {
    display: flex;
    flex: 0 0 auto;
    padding: 0 10px;
    justify-content: space-between;

    &.auto-width{
      flex: 1 1 auto;
    }

    &.no-padding-auto-width{
      flex: 1 1 auto;
      @include respond-up(large){
        padding-right: 0px;
      }
    }

    @include respond-up(large) {
      height: 100%;
    }

    &-item {
      position: relative;
      white-space: nowrap;
      cursor: pointer;


      @include respond-up(large) {
        height: 100%;
        align-items: center;
        display: flex;
      }


      &.active {

        .header__menu-link {
          .svg-icon {
            transform: rotateZ(90deg);
          }
        }
      }

      &:hover {
        @include respond-up(large) {
          .header__menu_sub {
            opacity: 1;
            pointer-events: all;
            transform: translateY(100%);
          }
        }
      }
    }

    &-title-wrapper {
      @include respond-down(middle) {
        flex: 0 0 200px;
        font-size: 14px;
      }
      @include respond-down(small) {
        flex: 1 1 auto;
        font-size: 14px;
      }
    }

    &-opener {

      text-align: center;
      width: 45px;
      height: 45px;
      line-height: 45px;
      position: relative;
      cursor: pointer;

      .svg-icon {
        height: 15px;
        width: 8px;
      }
    }

    &_sub {
      opacity: 0;
      position: absolute;
      bottom: 0px;
      left: -10px;
      pointer-events: none;
      border-top: 3px solid $orange;
      background: $white;
      z-index: -1;
      transition: all ease-out .2s;

      .header__menu-link {
        &:hover {
          color: $blue;
        }
      }

      &-title {
        display: none;
        margin-bottom: 24px;
        font-weight: $bold;
        font-size: 14px;
        color: $blue;
        text-transform: uppercase;
      }

      .link-wrapper {
        height: 54px;
        display: flex;
        align-items: center;
        width: 200px;
        padding: 0px 15px;
        border-bottom: 1px solid #B5BECB;
        font-size: 14px;
        transition: $default-transition;

        &:hover {
          background: rgba(223, 224, 227, 0.69);
        }
      }
    }


    &-link {
      position: relative;
      text-transform: uppercase;
      font-weight: $bold;
      @include textBold();
      @include respond-up(middle){
        margin-right: 20px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      @include respond-down(large){
        font-size: 12px;
      }
      &.active {
        &:before {
          width: 100%;
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        background: currentColor;
        width: 0;
        height: 2px;
        left: 0;
        bottom: 0px;
        right: 0;
        transition: width ease-out .3s;
      }

      &.has-subitems {
        @include respond-up(large) {
          &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 6px;
            top: calc(50% - 3px);
            transform: translateX(-50%);
            background: transparent url("../svg/arrow-menu-top-blue.svg") 0 0 no-repeat;
            display: inline-block;
            right: -20px;
          }
          &:before{
            display: none;
          }
        }
      }

      @include respond-up(large) {
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }

      @media(max-width: 1189px) {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        min-height: 55px;
        padding: 0px 25px;
        svg {
          transition: transform ease .2s;
        }
        &:hover {
          background-color: $orange;
          svg {
            color: $white;
          }
        }
      }

    }
  }

  &__links {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;


    .svg-icon {

      @include respond-down(middle) {
        &+.underline-element{
          font-size: 12px;
        }
      }
    }

  }

  &__link {
    @include textBold();
    @include respond-down(large){
      font-size: 12px;
    }

    &:last-child {
      padding: 0 0 0 1.822917vw;
      @include respond-down(large) {
        padding: 0 0 0 1vw;
      }
    }

    .svg-icon {
      flex: 0 0 30px;
      margin: 0 5px 0 0;

      &.icon-download-icon {
        margin: 0 8px 0 0;
        @include respond-down(small){
          margin: 0 5px 0 0;
        }
      }
    }
  }

  &__address {
    padding: 0 30px 0 0;
    @include text();
    font-size: 14px;
    @include respond-down(middle) {
      order: 2;
      padding: 0 15px;
    }
    @include respond(medium) {
      padding: 0 15px 0 0;
    }

    &-line {
      display: block;
    }

    &-link {
      color: $blue;
    }
  }

  &__call {
    @include respond-down(middle) {
      order: 2;
      padding: 5px 15px;
    }
  }

  &__phone {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    color: $blue;
    @include respond-down(large) {
      font-size: 15px;
    }

    .svg-icon {
      &.mail-yellow {
        width: 24px;
        height: 18px;
        background-size: cover;
      }
    }

    & + .button {
      padding: 6px 18px 9px 18px;
      margin-top: 5px;
      font-size: 13px;
      text-transform: none;
      font-weight: 400;
    }
  }

  &__phone-text {
    font-size: 12px;
  }

  &__ask-call {
    color: $blue;
    margin-left: 20px;

    span {
      margin-left: 10px;
    }

    @include respond-down(middle) {
      display: flex;
      flex-direction: column;
      font-size: 9px;
      color: #757575;
      text-align: center;
      line-height: 11px;
      text-transform: uppercase;
      align-items: center;
      font-weight: 500;
      i {
        margin: 0 0 6px 0px;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    flex: 0 1 auto;
    white-space: nowrap;
    align-items: center;
    @include respond(xxl) {
      padding: 0 0 0 2.6vw;
    }
    @include respond-down(middle) {
      flex: initial;
      padding: 0 36px;
    }
    @include respond(medium) {
      padding: 0 30px 0 36px;
    }
  }

  /**
      Mobile menu
   */

  &__mobile-menu {
    position: fixed;
    right: 0;
    text-align: left;
    width: 100%;
    max-width: 415px;
    height: 100%;
    margin-top: 75px;
    padding: 0 0 75px 0;
    overflow: auto;
    background: #fff;
    z-index: 999;
    transition: transform ease .62s;
    transform: translate(200%);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);

    &.active {
      transform: translate(0%);
    }
    @media(min-width:415px) {
      height: auto;
      padding-bottom: 0;
    }
    @media(min-width:784px) {
      margin-top: 85px;
    }
  }

  &__hamburger {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 62px;
    width: 62px;
    background: $orange;
    @include respond-down(middle){
      height: 85px;
    }
    @include respond-down(small){
      height: 75px;
    }

    span {
        position: absolute;
        top: 50%;
        width: 30px;
        height: 2px;
        background-color: $white;
        transition: all 0.1s ease 0s;

        &::before {
            content: "";
            position: absolute;
            top: 10px;
            width: 30px;
            height: 2px;
            background-color: $white;
            transition: all 0.3s ease 0s;
        }

        &::after {
            content: "";
            position: absolute;
            top: -10px;
            width: 30px;
            height: 2px;
            background-color: $white;
            transition: all 0.3s ease 0s;
        }
    }

    &._active {
        span {
            height: 0;

            &::before {
                transform: rotate(-45deg);
                top: 0;
            }

            &::after {
                transform: rotate(45deg);
                top: 0;
            }
        }
    }
  }

  &__submenu {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    background: #FFFFFF;
    padding-top: 0;
    transition: all .3s ease;

    &.active {
      padding: 10px 20px;
      opacity: 1;
      max-height: none;
      pointer-events: all;
      overflow: auto;
    }
  }

  &__subitem {
    padding: 3px 0;
    @include respond-down(small) {
      padding: 4px 0px;
      &:last-child {
        border-bottom: none;
      }
      .header__menu-link {
        font-size: 15px;
        text-transform: none;
        font-weight: 400;
        white-space: normal;
        padding-left: 0px;
        .title {
          text-align: right;
        }
      }
    }
    .svg-icon {
      width: 30px;
      height: 30px;
      background-size: 30px;
      margin-right: 10px;
    }
  }


  &__download-catalog {

    @include respond-down(middle) {
      .svg-icon {
        margin: 0 0 4px 0;
      }
      display: block;
      font-size: 9px;
      color: #757575;
      text-align: center;
      line-height: 11px;
    }
  }
  
  .header__phone-mobile, .header__wa-mobile {
      margin-right: 60px;

    @include respond-down(small){
      margin-right: 20px;
    }
  }
  
  .header__phone-mobile {
    @include respond-down(small){
      border-radius: 50%;
      padding: 10px;
      border: 1px solid $blue;
    }
    
    .phone {
      font-weight: bold;
      font-size: 18px;
      @include respond-down(small){
        display: none;
      }
      @media all and (max-width: 350px) {
        font-size: 13px;
      }
    }
  }
  
  .header__wa-mobile {
      svg {
          width: 39px;
          height: 39px;
      }
  }

  &__mobile-menu {
    @at-root &_links {
      display: flex;
      padding: 20px;
      flex-direction: column;
      background: $white;
      align-items: flex-start;

      &-text {
        display: block;
        font-size: 12px;
        font-weight: normal;
        text-transform: none;
      }
      @media(min-width:784px) {
        display: none;
      }
    }
    @at-root &_link {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        display: block;

        &.phone {
          font-weight: $bold;
          font-size: 18px;
          color: $blue;
        }

        &.callback {
          display: inline-block !important;
          font-size: 14px;
          color: $blue;
          font-weight: normal;
          text-transform: none;
          transition: all ease-out .2s;
          height: 35px;
          line-height: 35px;
          margin-top: 10px;
          font-weight: 500;

          &:hover {
            border-bottom-color: $t;
          }
        }
      }
    }


    .catalog-menu {
      max-height: 0px;

      &.active {
        max-height: none;
      }

      .root-catalog-link {
        display: flex;
        align-items: center;
        white-space: normal;

        .icon {
          display: flex;
          width: 50px;
          height: 50px;
          align-items: center;
          justify-content: flex-start;
          flex: 0 0 50px;

          img {
            object-fit: contain;
            max-width: 35px;
            max-height: 35px;

          }
        }
      }
    }

  }

  &__mobile-menu.active {
    .menu-holder-mobile-items.has-fixed {
      .header__menu-item {
        border-bottom: 1px solid $orange;
        @media(min-width:784px) {
          &:last-child {
            border-bottom: none;
          }
        }
    
        .header__menu-title-wrapper {
          @include respond-down(middle) {
            flex: 0 0 235px;
          }
    
          @include respond-down(small) {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__submenu {
    &.active {
      .header__subitem {
        display: block;
        border-bottom: 1px solid #9faabb;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.ru .header {
  &__subitem {
    display: none;
  }
}

.header__mobile-menu_bottom {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: -5px;
    background: transparent url("../svg/menu-shadow-horizontal.svg") 0 0 no-repeat;
  }

  .phone-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include respond-down(middle) {
      width: 330px;
    }

    @include respond-down(small) {
      width: 100%;
      max-width: 415px;
    }
    &__number {
      width: 162px;

      a.phone {
        font-size: 16px;
      }

      .header__mobile-menu_links-text {
        text-align: center;
      }
    }

    &__button {
      width: 152px;

      a {
        margin: 0;
      }
    }

    .vertical-dash {
      width: 1px;
      height: 35px;
      border-left :1px solid rgba(159, 170, 187, 0.7);
    }
  }
}


.catalogue-menu {
  &.smallest-menu{
    .header__menu-item {
      &.catalog-item {
        .header__menu_sub {

          &.catalog {
            .catalog-menu {

              &.children-count-2{
                position: static;
              }

              .root-catalog-link {
                height: 65px;

                .dropper{
                  height: 65px;
                }
              }
            }
          }
        }
      }
    }

  }
}

.sale-department-email{
  display: flex;
  white-space: nowrap;

  .icon{
    flex: 0 0 auto;
    margin-right: 5px;
    .svg-icon.mail-yellow{
      width: 24px;
      height: 19px;
      background-size: cover;
    }
  }
  .text{
    span{
      display: block;
      font-size: 15px;
    }
    .info{
      font-size: 14px;
      color: #909090;
    }
  }
}

.header-left-holder{
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  @include respond-down(small){
    justify-content: space-between;
  }
}

.header-right-holder{
  display: flex;
  align-items: center;
}


.centered-header-link-down-load{
  flex: 1 1 auto;
  text-align: center;
  margin-right: 0px;

  max-width: 200px;
  margin-left: 30px;

  @include respond-down(middle){
    text-align: right;
  }

  @include respond-down(small){
    display: none;
  }

  &.en {
    @include respond-down(middle){
      display: none;
    }
  }
}
